import { combineReducers } from "@reduxjs/toolkit";
import dashboardReducer from '../services/DashboardSlice';
import assessmentReducer from '../services/AssessmentSlice';
import notificationReducer from '../services/NotificationSlice';
import userReducer from '../services/UserSlice';
import createTemplateReducer from '../services/CreateTemplateSlice';
import coverLetterReducer from '../services/CoverLettersSlice';
import loadReducer from '../services/LoaderSlice';
import templateReducer from '../services/TemplatesSlice';
import applicationsHandler from "../services/ApplicationsSlice";
import screenHandler from "../services/ScreenSlice";


const appReducer = combineReducers({
    dashboardHandler: dashboardReducer,
    assessmentHandler: assessmentReducer,
    notificationHandler: notificationReducer,
    userHandler: userReducer,
    createTemplateHandler: createTemplateReducer,
    coverLetterHandler: coverLetterReducer,
    loadHandler: loadReducer,
    templateHandler: templateReducer,
    applicationsHandler: applicationsHandler,
    screenHandler: screenHandler,
})

const rootReducer = (state, actions) => {
    return appReducer(state, actions)
}

export default rootReducer;