import { setApisLoading } from "./services/LoaderSlice";
import { setBody, setType } from "./services/NotificationSlice"

const prodBackend = `https://api.resumlink.com/pviewerApi`
const testBackend = `https://testbe.tedoratech.com/pviewerApi`;
// const backend = `http://localhost:8000/pviewerApi`

const skipErrorFor = ['/user'];
const reloadFor = ['/user', '/user/login', '/user/signup', '/user/forgotpwd', '/user/resetpwd'];
const skipLoadingFor = [];

export const HTTPRequest = (method, url, body, succesHandler, errorHandler, type, background) => {
    return (dispatch, getState) => {
        if(skipLoadingFor.indexOf(url) === -1 && !Boolean(background)){
            const apisLoading = getState().loadHandler.apisLoading;
            dispatch(setApisLoading([...apisLoading, url]));
        }
        var requestOptions = {
            method: method,
            headers: {
                "Authorization": localStorage.getItem("accessToken")
            }
        }
    
        if((method === 'POST' || method === 'PUT') && type !== 'form'){
            requestOptions['body'] = JSON.stringify(body);
            requestOptions.headers["Content-Type"] = "application/json";
        } else if(method === 'POST' || method === 'PUT') {
            requestOptions['body'] = body;
        }

        const backend = window.location.hostname === 'portal.resumlink.com' ? prodBackend : testBackend;
        fetch(`${backend}${url}`, requestOptions).then((response)=> {
            const loadingUrls = getState().loadHandler.apisLoading;
            dispatch(setApisLoading(loadingUrls.filter(uri => uri !== url)));
            if(response.status !== 404){
                response.json().then(data => {
                    if(response.ok){
                        if(typeof(succesHandler) === 'function'){
                            succesHandler(data)
                        }
                    } else {
                        if(response.status === 401 && reloadFor.indexOf(url) === -1){
                            localStorage.removeItem("accessToken");
                            window.location.reload();
                            return;
                        }
                        if(typeof(errorHandler) === 'function'){
                            errorHandler(data)
                        } else {
                            if(skipErrorFor.indexOf(url) === -1){
                                dispatch(setType('error'));
                                dispatch(setBody(data.message));
                            }
                        }
                    }
                })   
            } else {
                errorHandler({message: 'Internal server error'})
            }
        }).catch(error => {
            const loadingUrls = getState().loadHandler.apisLoading;
            dispatch(setApisLoading(loadingUrls.filter(uri => uri !== url)));
            if(typeof(errorHandler) === 'function'){
                errorHandler(error)
            }
        })
    }
    
}