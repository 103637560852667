import { Box, Tab, Tabs } from "@mui/material";
import PVTable from "../../Custom/PVTable";
import PVForms from "../../Custom/PVForms";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { assessmentsSelector, createNewAssessment, deleteAssessement, generateAssessmentResults, getAssessments, setAssessmentId, toggleArchive } from "../../services/AssessmentSlice";
import { useNavigate } from "react-router-dom";
import PVConfirm from "../../Custom/PVConfirm";
import { copyDataFromAssessment } from "../../services/CoverLettersSlice";
import { getDocuments, uploadedDocumentsSelector } from "../../services/CreateTemplateSlice";
import { Add, Refresh } from "@mui/icons-material";

const Assessment = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [newAssessment, setNewAssessment] = useState({open: false});
    const [viewOnly, setViewOnly] = useState(false);
    const [open, setOpen] = useState(false);
    const [row, setRow] = useState(false);
    const [tabValue, setTabValue] = useState(0);
    const [newCl, setNewCl] = useState({});
    const uploadedDocuments = useSelector(uploadedDocumentsSelector);

    const assessments = useSelector(assessmentsSelector);

    const statuses = [
        { value: 'READY', label: 'Ready' },
        { value: 'ASSESS_ANSWERS', label: 'Assess Answers' },
        { value: 'RESULTS_READY', label: 'Results Ready' },
        { value: 'PARSE', label: 'Processing' },
        { value: 'PROCESSING_RESULT', label: 'Processing Result' },
    ]

    const createAssessment = (data) => {
        dispatch(createNewAssessment(data.editRecord));
        setNewAssessment({ open: false })
        setViewOnly(false);
    }

    const rowClick = (row, key) => {
        switch(key) {
            case 'seeDetails':
                setNewAssessment({open: true, editRecord: row});
                setViewOnly(true);
                break;
            case 'genResults':
                dispatch(generateAssessmentResults(row.assessmentId));
                break;
            case 'startAssessment':
                setOpen(true)
                setRow(row);
                break;
            case 'openAnalytics':
                dispatch(setAssessmentId(row.assessmentId));
                navigate('/analytics');
                break;
            case 'archive':
                dispatch(toggleArchive(row.assessmentId));
                break;
            case 'downloadCoverLetter':
                setNewCl({open: true, editRecord: {assessmentId: row.assessmentId}})
                break;
            case 'delete':
                dispatch(deleteAssessement(row.assessmentId));
                break;
            default:
                setNewAssessment({open: true, editRecord: row})
        }
    }

    const createNewCl = () => {
        dispatch(copyDataFromAssessment({
            assessmentId: newCl.editRecord.assessmentId,
            documentId: newCl.editRecord.documentId
        }))
        navigate('/cl');
    }

    useEffect(() => {
        dispatch(getAssessments())
        dispatch(getDocuments());
    }, [dispatch]);

    return(
        <Box>
            <PVForms
                record={newCl}
                onChangeRecord={setNewCl}
                title={'Generate Cover Letter'}
                onSaveClicked={createNewCl}
                onCancelClicked={() => {
                    setNewCl({ open: false })
                }}
                fields={[
                    {
                        property: 'documentId',
                        label: 'Reference Document',
                        listValues: uploadedDocuments,
                        listValueKey: 'documentId',
                        listLabelKey: 'fileName',
                        type: 'SELECT',
                        required: true
                    }
                ]}
            />
            <PVForms 
                record={newAssessment}
                onChangeRecord={setNewAssessment}
                title={'Create New Assessment'}
                onSaveClicked={createAssessment}
                onCancelClicked={() => {
                    setNewAssessment({ open: false })
                    setViewOnly(false);
                }}
                fields={[
                    {
                        property: 'jobTitle',
                        label: 'Job Title',
                        required: true,
                        disabled: viewOnly
                    },
                    {
                        property: 'jobDescription',
                        label: 'Job Description(Min 200 characters)',
                        rows: 8,
                        maxRows: 8,
                        type: 'TEXTAREA',
                        required: true,
                        minLength: 200,
                        disabled: viewOnly
                    }
                ]}
            />
            <PVConfirm 
                open={open}
                title={'Confirm'}
                confirmMessage={'Are you sure you want to start the assessment ?'}
                cancelClicked={() => setOpen(false)}
                saveClicked={() => {
                    setOpen(false);
                    dispatch(setAssessmentId(row.assessmentId));
                    navigate('/qa');
                }}
            />
            <div>
                <Tabs value={tabValue} onChange={(e, v) => setTabValue(v)}>
                    <Tab label={'Active'}/>
                    <Tab label={'Archived'}/>
                </Tabs>
                <PVTable 
                    style={{marginTop: 10}}
                    searchable
                    actions={[
                        {
                            property: 'refresh',
                            label: 'Refresh',
                            onClick: (event) => {dispatch(getAssessments())},
                            icon: <Refresh />
                        },
                        {
                            property: 'newAssessment',
                            label: 'New Assessment',
                            onClick: (event) => {setNewAssessment({open: true, editRecord: {}})},
                            icon: <Add />
                        }
                    ]}
                    columns={[
                        {
                            label: 'Number',
                            property: 'count',
                            filterable: false
                        },
                        {
                            label: 'Job Title',
                            property: 'jobTitle',
                            maxLength: 30,
                        },
                        {
                            label: 'Job Description',
                            property: 'jobDescription',
                            maxLength: 50,
                        },
                        {
                            label: 'Status',
                            property: 'status',
                            calculated: (row) => statuses.find(status => status.value === row?.status)?.label
                        },
                        {
                            label: 'Created On',
                            property: 'createdAt',
                            type: 'date'
                        }
                    ]}
                    data={assessments?.filter(assessment => assessment?.archived === (tabValue === 0 ? false : true))}
                    clickMenu={[
                        {
                            label: 'See Details',
                            key: 'seeDetails'
                        },
                        {
                            label: 'Generate Results',
                            key: 'genResults',
                            enabled: (row) => row?.status === 'ASSESS_ANSWERS'
                        },
                        {
                            label: 'Generate Cover Letter',
                            key: 'downloadCoverLetter',
                            enabled: (row) => row?.jobDescription !== undefined && row?.jobDescription !== ''
                        },
                        {
                            label: 'Start Assessment',
                            key: 'startAssessment',
                            enabled: (row) => row?.status === 'READY'
                        },
                        {
                            label: 'Open Analytics',
                            key: 'openAnalytics',
                            enabled: (row) => row?.status === 'ASSESS_ANSWERS' || row?.status === 'RESULTS_READY'
                        },
                        {
                            label: 'Archive',
                            key: 'archive',
                            visible: (row) => row?.archived === false
                        },
                        {
                            label: 'Unarchive',
                            key: 'archive',
                            visible: (row) => row?.archived === true
                        },
                        {
                            label: 'Delete',
                            key: 'delete'
                        }
                    ]}
                    onRowClick={rowClick}
                    paginatied
                />
            </div>
        </Box>
    )

}

export default Assessment;