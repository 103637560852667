import { useDispatch, useSelector } from "react-redux";
import PVTable from "../../Custom/PVTable";
import { coverLettersSelector, createCoverLetterRequest, deleteCoverLetter, getCoverLetterRequests, rewriteCoverLetter } from "../../services/CoverLettersSlice";
import { useEffect, useState } from "react";
import PVForms from "../../Custom/PVForms";
import { Box } from "@mui/material";
import { getDocuments, uploadedDocumentsSelector } from "../../services/CreateTemplateSlice";
import { Add, Refresh } from "@mui/icons-material";

const CoverLetters = () => {

    const [newCl, setNewCl] = useState({});
    const [viewOnly, setViewOnly] = useState(false);

    const coverLetters = useSelector(coverLettersSelector);
    const uploadedDocuments = useSelector(uploadedDocumentsSelector);

    const dispatch = useDispatch();

    const statuses = [
        { value: 'PENDING_PARSE', label: 'Getting data' },
        { value: 'READY', label: 'Ready for GPT Process' },
        { value: 'PROCESSING', label: 'GPT Processing' },
        { value: 'QUEUED_TO_SEND', label: 'Waiting for S3 Upload' },
        { value: 'CREATED_AND_UPLOADED', label: 'Ready for Download' },
        { value: 'ERROR', label: 'Error' }
    ]

    const createNewCl = (data) => {
        dispatch(createCoverLetterRequest(data.editRecord));
        setNewCl({ open: false })
    }

    const rewriteCl = (data) => {
        dispatch(rewriteCoverLetter(data.editRecord._id, data.editRecord));
        setNewCl({ open: false })
    }

    const rowClick = (row, key) => {
        switch(key) {
            case 'seeDetails':
                setNewCl({open: true, editRecord: {...row, clLength: row.clLength || 'SHORT'}})
                setViewOnly(true);
                break;
            case 'downloadCoverLetter':
                window.open(row.coverLetterURL);
                break;
            case 'delete':
                dispatch(deleteCoverLetter(row._id));
                break;
            case 'rewrite':
                setNewCl({open: true, editRecord: {...row, clLength: row.clLength || 'SHORT'}, rewrite: true})
                break;
            default:
                console.log("No click available");
        }
    }

    useEffect(() => {
        dispatch(getCoverLetterRequests());
        dispatch(getDocuments());
    }, [dispatch])

    return (
        <Box>
            <PVForms
                record={newCl}
                onChangeRecord={setNewCl}
                title={newCl.rewrite ? 'Rewrite Cover Letter' : 'New Cover Letter'}
                onSaveClicked={newCl.rewrite ? rewriteCl : createNewCl}
                saveEnabled={newCl.rewrite ? true : newCl.editRecord?.jobTitle && newCl.editRecord?.jobDescription && newCl.editRecord?.documentId}
                onCancelClicked={() => {
                    setNewCl({ open: false })
                    setViewOnly(false);
                }}
                fields={[
                    {
                        property: 'jobTitle',
                        label: 'Job Title',
                        required: true,
                        disabled: viewOnly || newCl.rewrite
                    },
                    {
                        property: 'jobDescription',
                        label: 'Job Description(Min 200 characters)',
                        rows: 8,
                        maxRows: 8,
                        type: 'TEXTAREA',
                        required: true,
                        minLength: 200,
                        disabled: viewOnly || newCl.rewrite
                    },
                    {
                        property: 'documentId',
                        label: 'Reference Document',
                        listValues: uploadedDocuments.filter(d => d.metaData !== undefined && d.isResume === true),
                        listValueKey: 'documentId',
                        listLabelKey: 'fileName',
                        type: 'SELECT',
                        required: true,
                        disabled: viewOnly || newCl.rewrite
                    },
                    {
                        property: 'clLength',
                        label: 'Length',
                        type: 'SELECT',
                        listValues: [
                            { value: 'SHORT', label: 'Short' },
                            { value: 'MEDIUM', label: 'Medium' },
                            { value: 'LONG', label: 'Long' }
                        ],
                        listLabelKey: 'label',
                        listValueKey: 'value',
                        required: true,
                        disabled: viewOnly
                    }
                ]}
            />
            <PVTable 
                style={{marginTop: 10}}
                searchable
                actions={[
                    {
                        property: 'refresh',
                        label: 'Refresh',
                        onClick: (e) => dispatch(getCoverLetterRequests()),
                        icon: <Refresh />
                    },
                    {
                        property: 'newCl',
                        label: 'New Cover Letter',
                        onClick: (event) => {setNewCl({open: true, editRecord: {clLength: 'SHORT'}})},
                        icon: <Add />
                    }
                ]}
                columns={[
                    {
                        label: 'Number',
                        property: 'count',
                        filterable: false
                    },
                    {
                        label: 'Job Title',
                        property: 'jobTitle',
                        maxLength: 30
                    },
                    {
                        label: 'Status',
                        property: 'status',
                        calculated: (row) => statuses.find(s => s.value === row.status)?.label
                    },
                    {
                        label: 'Created On',
                        property: 'createdAt',
                        type: 'date'
                    }
                ]}
                data={coverLetters}
                clickMenu={[
                    {
                        label: 'See Details',
                        key: 'seeDetails'
                    },
                    {
                        label: 'Download Cover Letter',
                        key: 'downloadCoverLetter',
                        enabled: (row) => row?.status === 'CREATED_AND_UPLOADED'
                    },
                    {
                        label: 'Rewrite',
                        key: 'rewrite',
                        enabled: (row) => row?.status === 'CREATED_AND_UPLOADED'
                    },
                    {
                        label: 'Delete',
                        key: 'delete'
                    }
                ]}
                onRowClick={rowClick}
                paginatied
            />
        </Box>
    )

}

export default CoverLetters;