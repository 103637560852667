import { Box, Button, Hidden, Menu, MenuItem, Paper, Select, SpeedDial, SpeedDialAction, SpeedDialIcon, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";

const PVTable = ({columns, data, searchable, searchLabel,
                    onRowClick, clickMenu, actions, style,
                    inPopup, rowStyle, paginatied}) => {

    const [searchItem, setSearchItem] =  useState('');
    const [searchText, setSearchText] = useState('');
    const [searchData, setSearchData] = useState([]);
    const [control, setControl] = useState({});
    const [speedDialOpen, setSpeedDialOpen] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);

    useEffect(() => {
        if(searchItem === ''){
            if(columns.filter(col => col.filterable !== false).length > 0);
            let defaultItem = columns.filter(col => col.filterable !== false)[0];
            setSearchItem(defaultItem.property);
        }
    }, [columns, searchItem])

    useEffect(() => {
        setSearchData(data);
    }, [data])

    useEffect(() => {
        if(searchText){
            let row = columns.filter(col => col?.property === searchItem)[0];
            let searchedData = data.filter(d => String(getColumnValue(d, row, 0)).toLowerCase().includes(String(searchText).toLowerCase()));
            setSearchData(searchedData);
        }else {
            setSearchData(data);
        }
    }, [searchItem, searchText, data, columns]);

    const getColumnValue = (row, column, i) => {
        if(typeof(column.calculated) === 'function'){
            return column.calculated(row);
        } else if(column.property === 'count') {
            return String(i+1);
        } else if (column.maxLength && row[column.property]) {
            return `${String(row[column.property]).substring(0, column.maxLength)}${String(row[column.property]).length > column.maxLength ? '...' : ''}`;
        } else if (column.type === 'date'){
            return new Date(row[column.property]).toLocaleDateString() === 'Invalid Date' ? '' : new Date(row[column.property]).toLocaleDateString();
        } else if (column.type === 'dateTime'){
            return new Date(row[column.property]).toLocaleString() === 'Invalid Date' ? '' : new Date(row[column.property]).toLocaleString();
        } else {
            return row[column.property];
        }
    }

    return(
        <div>
            <Menu 
                open={control.x  && control.y}
                anchorReference='anchorPosition'
                disableAutoFocusItem
                anchorPosition={{left: control.x, top: control.y}}
                onClose={() => setControl({})}
            >
                {clickMenu?.map((item, i) => {
                    let enabled = true;
                    if(typeof(item?.enabled) === 'function'){
                        enabled = item?.enabled(control.row);
                    }
                    if(typeof(item?.enabled) === 'boolean'){
                        enabled = item.enabled;
                    }
                    if(typeof(item?.visible) === 'function'){
                        if(!item.visible(control.row)) 
                            return(<Hidden xlDown/>);
                    }
                    if(typeof(item?.visible) === 'boolean') {
                        if(!item.visible){
                            return(<Hidden xlDown/>);
                        }
                    }
                    return(
                        <MenuItem key={i} 
                            disabled={!enabled}
                            onClick={() => {if(typeof(onRowClick) === 'function') {onRowClick(control.row, item.key);setControl({})}}}>
                            {item.label}
                        </MenuItem>
                    )
                })}
            </Menu>
            <div style={{display: 'flex', flexDirection: 'row', ...style}}>
                <Hidden xlDown={!searchable}>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <TextField label={searchLabel || 'Search'} variant="outlined"
                                    size="small"
                                    style={{marginLeft: 5}}
                                    onChange={(e) => {setSearchText(e.target.value)}}/>
                        <Select onChange={(e) => setSearchItem(e.target.value)}
                                style={{marginLeft: 5}}
                                value={searchItem} size="small">
                            {columns.filter(col => col.filterable !== false).map(col => (
                                <MenuItem key={col.property} value={col.property}>{col.label}</MenuItem>
                            ))}
                        </Select>
                    </div>
                </Hidden>
                <Box sx={{display: {sm: 'none', xs: 'none', md: 'block'}}}>
                    <Hidden xlDown={actions?.length === 0}>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            {actions?.map((action, i) => (
                                <Button
                                    variant="outlined"
                                    key={action.property}
                                    style={{marginLeft: 5}}
                                    onClick={(event) => {
                                        if(typeof(action.onClick) === 'function'){
                                            action.onClick(event);
                                        }
                                    }}
                                >{action.label}</Button>
                            ))}
                        </div>
                    </Hidden>
                </Box>
            </div>
            <Paper>
                <TableContainer style={{marginTop: 20}} sx={{ width: Boolean(inPopup) ? '100%' : { sm: '100%', xs: '90%'} }}>
                    <Table sx={{minWidth: 650}} size="small">
                        <TableHead>
                            <TableRow>
                                {columns.map((column, i) => (
                                    <TableCell key={column.label} style={{padding: 10}}>
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(paginatied ? searchData.slice(page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage) : searchData).map((row, i) => (
                                <TableRow 
                                    key={i}
                                    style={typeof(rowStyle) === 'function' ? 
                                        {...rowStyle(row, i), cursor: typeof(onRowClick) === 'function' ? 'pointer' : ''} : {
                                        cursor: typeof(onRowClick) === 'function' ? 'pointer' : '',
                                    }}
                                    onClick={(event) => {
                                        if(clickMenu?.length > 0){
                                            setControl({
                                                x: event.clientX,
                                                y: event.clientY,
                                                row: row
                                            });
                                            return;
                                        }
                                        if(typeof(onRowClick) === 'function'){onRowClick(row);return;}
                                    }}>
                                    {columns.map((column, j) => (
                                        <TableCell key={column.label + '' + i} style={{padding: 10}}>
                                            {getColumnValue(row, column, i)}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {paginatied && <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={searchData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={(event, newPage) => setPage(newPage)}
                        onRowsPerPageChange={(event) => { setRowsPerPage(event.target.value)}}
                />}
            </Paper>
            <Box sx={{display: {sm: 'block', xs: 'block', md: 'none'}, marginTop: Boolean(inPopup) ? 7 : 'none'}}>
                <SpeedDial
                    ariaLabel="SpeedDial tooltip example"
                    sx={{ position: 'absolute', bottom: 16, right: 16 }}
                    icon={<SpeedDialIcon />}
                    onClose={() => setSpeedDialOpen(false)}
                    onOpen={() => setSpeedDialOpen(true)}
                    open={Boolean(speedDialOpen)}
                >
                    {actions?.map((action, i) => (
                        <SpeedDialAction
                            key={action.property}
                            icon={action?.icon}
                            tooltipTitle={action.label}
                            titlePlacement="bottom" // Add this line
                            style={{width: '100%'}}
                            onClick={() => {
                                setSpeedDialOpen(false);
                                if(typeof(action.onClick) === 'function'){
                                    action.onClick();
                                }
                            }}
                        />
                    ))}
                </SpeedDial>
            </Box>
        </div>
    )

}

PVTable.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        property: PropTypes.string.isRequired,
        filterable: PropTypes.bool,
        maxLength: PropTypes.number,
        calculated: PropTypes.func,
        type: PropTypes.string
    })).isRequired,
    style: PropTypes.object,
    searchable: PropTypes.bool,
    actions: PropTypes.arrayOf(PropTypes.shape({
        property: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
        icon: PropTypes.element
    })),
    data: PropTypes.array.isRequired,
    clickMenu: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        key: PropTypes.string.isRequired,
        enabled: PropTypes.oneOfType([
            PropTypes.bool,
            PropTypes.func
        ])
    })),
    onRowClick: PropTypes.func.isRequired,
    inPopup: PropTypes.bool,
    rowStyle: PropTypes.func,
    paginatied: PropTypes.bool
}

export default PVTable;